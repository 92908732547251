<template>
<div class="recruit-box left clearfix">
  <Header></Header>
  <!--顶部banner开始-->
  <div class="aboutus-banner left"> <img src="~images/banner/gongchenghouqiweihu.jpg" width="100%" /> </div>

  <div class="contact-content width-bai left clearfix">
                <div class="contact-contents center clearfix" style="padding-top:30px;">

                <h1 style="margin-left:30px;">实验</h1>
                <div class="recruit-form">
                    <Form :model="formItem" :label-width="100" :label-position="labelPosition" :rules="ruleValidate" ref="formItem" style="width:70%;margin-left:15%;">
                       
                        <FormItem label="联系姓名" prop="name">
                            <Input v-model="formItem.name" style="margin-left:-65px;width:600px;" placeholder="请输入姓名"></Input>
                        </FormItem>
                        <FormItem label="联系电话" prop="tel">
                            <Input v-model="formItem.tel" style="margin-left:-65px;width:600px;" placeholder="请输入您的常用联系电话"></Input>
                        </FormItem>

                        <FormItem label="维修地址" prop="address">
                            <Input v-model="formItem.address" style="margin-left:-65px;width:600px;" placeholder="请输入您的维修地址"></Input>
                        </FormItem>
                        
                        <FormItem label="相关说明" prop="content">
                            <Input v-model="formItem.content" style="margin-left:-65px;width:600px;" type="textarea" :autosize="{minRows: 10,maxRows: 10}" placeholder="请输入内容"></Input>
                        </FormItem>
                        
                         <FormItem label="配件"> </FormItem>

                        <div class="main-goods left clearfix">
                                <div :id="'g'+item.id" label="false" class="main-goods-li left clearfix" @click="setValue(item.id)" v-for="item in goods" :key="item.id">
                                    <input type="checkbox" style="display:none;" :id="'checkbox'+item.id" v-model="formItem.goodsValue" :value="item.id" />
                                    <div class="main-goods-img left">
                                        <img :src="item.img_path" :title="item.title" />
                                    </div>
                                    <p>{{item.title}}</p>
                                    <p><label><font>￥{{item.price}}</font><label>会员价</label></label> </p>
                                </div> 
                           </div>
                    <div class="form-button left" style="margin-bottom:30px;">
                        <FormItem> <Button type="primary" @click="handleSubmit('formItem')" style="width:120px;">立即申请</Button> </FormItem>
                    </div>
                    </Form>
                </div>
                </div>
                </div>
        <div class="left clearfix width-bai"> <Footer></Footer> </div>
        <contact-call></contact-call>
</div>
</template>
<style>
    .main-goods-li p label{float:left;}
    .main-goods-li p{line-height:30px;padding-left:10px;padding-right:10px;font-size:14px;}
    .main-goods-li p label label{padding:1px 5px;margin-top:6px;line-height:16px;background:#f40;color:#fff;font-size:10px;margin-right:5px;}
    .main-goods-li p label font{font-size:16px;color:#f40;font-weight:bold;}
    .main-goods-img img:hover{transform: scale(1.4);}
    .main-goods-img img{width:180px;min-height:180px;transition: all 2s;cursor:pointer;}
    .main-goods-img{width:176px;height:176px;overflow:hidden;}
    .current-goods{border:2px solid red;background:url('~images/xuanzhong.png') no-repeat right 210px;}
    .main-goods-li:hover{border:2px solid red;background:url('~images/xuanzhong.png') no-repeat right 210px;}
    .main-goods-li{border:2px solid #eee;cursor:pointer;margin:26px;width:180px;height:250px;border-radius:5px;box-shadow: 0px 2px 5px #cccccc;}
    .main-goods{width:100%;min-height:400px;margin-top:-50px;margin-left:40px;}
    .table_title{font-weight:bold;}
    .project_text table tr td{width:25%;height:50px;border:1px solid #eee;text-align:center;font-size:14px;line-height:25px;}
    .project_text table{width:90%;margin-left:5%;margin-top:50px;}
    .jindutiao{margin-top:60px;width:90%;margin-left:5%;}
    h2{line-height:50px;text-align:center;font-size:16px;border:1px solid #eee;margin-top:100px;}
    .ivu-form-item-content{margin-top:10px;}
    .ivu-form-item-error-tip{margin-left:0px;line-height:20px;margin-left:-40px;}
    .ivu-form .ivu-form-item-label{padding-top:3px;font-weight:bold;margin-top:10px;}
    .recruit-box h1{font-size:15px;border-left:5px solid rgb(242,151,0);padding-left:10px;margin-bottom:40px;}
    .contact-text{width:100%;min-height:800px;margin-top:20px;}
    .contact-contents{min-height:900px;width:1440px;margin-top:10px;font-size:16px;box-shadow: 0px 2px 5px #cccccc;margin-top:50px;padding-bottom:50px;}
    .contact-content{min-height:900px;}
    .form-label input{width:23%;margin-left:1%;margin-right:1%;line-height:30px;border:1px solid #ddd;border-radius:5px;padding-left:5px;}
    .form-button{width:100%;height:60px;margin-top:30px;text-align:center;}
    .form-button Button{margin-top:50px;margin-bottom:50px;margin-right:20px;}
    .form-label{width:100%;line-height:40px;}
    .form-label label{width:23%;float:left;margin-left:1%;margin-right:1%;text-align:center;font-weight:bold;font-size:15px;}
    .form-title{width:100%;line-height:60px;font-weight:bold;font-size:15px;}
</style>
<script>
import $ from 'jquery'
import ContactCall from '../index/ContactCall.vue'
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
export default {
        data () {
            
            return {
                labelPosition:'top',
                goods:'',
                formItem: {
                    name:'',
                    tel:'',
                    address:'',
                    content:'',
                    goodsValue:[]
                    
                },
                ruleValidate: {
                    name: [
                        { required: true, message: '请填写联系人', trigger: 'blur' }
                    ],
                    tel: [
                        { required: true, message: '请填写联系电话', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '请填写维修地址', trigger: 'blur' }
                    ],
                    content:[
                        {required:true,message:'请填写相关描述',trigger:'blur'}
                    ],
                    goodsValue:[
                        {required:true,type: 'array', min: 1,message:'请至少选择一个商品',trigger: 'change'}
                    ]
                    

                }

            }
            

        },
        components:{
            ContactCall,
            Header,
            Footer
        },
        mounted(){
            
            //调用商品接口
            this.$axios({method:'post',url:window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_goods.html?token='+this.$store.state.token})
						.then(res=>{
                            this.goods=res.data.result;
                        });
        },
methods: {
            handleSubmit (name) {
                //验证是否选择商品
                if(!this.formItem.goodsValue[0]){
                    this.$Modal.error({
                                    title: '温馨提示',
                                    content: '请至少选择一个商品',
                                });
                                return false;
                }
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        this.formItem.token=this.$store.state.token;
                        this.formItem.username=eval('(' +this.$store.state.userinfo+ ')').username;
                        this.formItem.m_type=4;
                        var param=JSON.stringify(this.formItem);
                        param = eval('(' + param + ')');
                        this.$axios({method:'post',url:window.configs.svcProt + "://" + window.configs.svcIpPort + '/submit_maintenancel.html',params:param}).then(res=>{
                            console.log(res.data);
                            if(res.data.result==true){
                                this.$Modal.success({
                                    title: '温馨提示',
                                    content: '申请成功，客服会尽快电话联系您，请耐心等待··',
                                    onOk: () => {
                                        location.href="/recruit"
                                    },
                                });
                            }
                        });
                        
                    } 
                })
            },

            setValue(id){
                    var label = $('#g'+id).attr('label');
                    var goods = this.formItem.goodsValue;
                    if(label == 'false'){
                        $('#g'+id).addClass('current-goods');
                        $('#g'+id).attr('label','true');
                        goods.push(id);
                    }else{
                        $('#g'+id).removeClass('current-goods');
                        $('#g'+id).attr('label','false');
                        goods.splice($.inArray(id, goods), 1);

                    }
            }
}
}
</script>

